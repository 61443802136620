
.nav-bar{
  background-color: #0c3560;
  width: 98% !important;
  margin-left: 1%;
}

.nav-menu {
  height: 2.5em;
  /* display: flex;
  flex-wrap: wrap;
  align-content: center; */
}

.nav-menu a{
  float: left;
  height: 100%;
  color: rgb(255,255,255);
  text-decoration: none;
  padding: 0.3em 1em 0em 1em;
}

.nav-menu a:hover{  
  color: rgb(223, 223, 223);
}

.active {
  /* border-bottom: 0.1em solid #fff; */
  background-color: #3983ce85;
}

.ant-btn.ant-btn-primary.ant-btn-icon-only.nav-bar-button{
  float: right;
  display: flex;
  justify-content: center;
  height: 2em;
  margin-top: 0.25em;
  background-color: #0c3560;
  border-color: #0c3560;
}

.ant-btn.ant-btn-icon-only{
  float: right;
  display: flex;
  justify-content: center;
  height: 2em;
  margin-top: 0.25em;
  background-color: #0c3560;
  border-color: #0c3560;
}