.top-bar{
    background-color: #F9FBFF;
    width: 100%;
}

.image{
    display: "block";
}

img{
    display: "block";
}
