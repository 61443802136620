.rstm-toggle-icon {
    display: inline-block; }

.rstm-toggle-icon-symbol {
      width: 1.5rem;
      height: 1.5rem;
      /* text-align: center; */
      line-height: 1.5rem;
      color: whitesmoke;
}
  
.rstm-tree-item-group {
    list-style-type: none;
    padding-left: 0;
    border-top: 1px solid whitesmoke;
    text-align: left;
    width: 100%; 
    background-color: #0c3560;
}
  
.rstm-tree-item {
    padding: 0.6rem 1rem;
    cursor: pointer;
    color: #ffffff;
    background: none;
    border: 1px solid whitesmoke;
    box-shadow: none;
    z-index: unset;
    position: relative; 
    font-size: small;
    transition-duration: 0.5s;
}
.rstm-tree-item:hover {
    background-color: #ed1c2e;
}

.rstm-tree-item--active {
      color: white;
      background: #179ed3;
      border-bottom: none; }

.rstm-tree-item--focused {
      box-shadow: 0 0 5px 0 #222;
      z-index: 999; }
  
.rstm-search {
    padding: 1rem 1.5rem;
    border: 1px solid #0c3560;
    width: 100%; 
    height: 2.4rem;
    background-color: #e9f1f9;
}

.rstm-tree-item rstm-tree-item-level2:required{
    padding-left: 6rem;
}
 