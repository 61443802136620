
.app {
  height: 100vh;
  min-height: 100vh;
  width: 100%;
  min-width: 100%;
  background-color: rgb(249, 251, 255);
}

.container{
  height: 100%;
  min-height: 100%;
  width: 99%;
  max-width: 99%;
  margin: 0 0% 0 0;
  padding: 0 0 0 1%;
  min-width: 99%;
}