/* header { background-color: rgb(100, 108, 140); color: white; margin: 50px;} */

.ant-table.ant-table-small{
  font-size: small;
}

.ant-table-header{
  overflow: hidden;
  white-space: nowrap;
}

.ant-table-thead > tr >th{
  color: rgb(255, 255, 255);
  background-position: center center;
  background-origin: content-box;
  background: rgba(23, 77, 134, 0.8) !important;
  text-align: center;
  position: sticky;
  text-overflow: unset;
}

.ant-table-tbody > tr > td{
  font-size: 1em;
  color: rgb(47, 73, 90);
  background: #e9f1f9;
  /* border-color: rgb(217, 220, 253); */
}

.ant-table-tbody > tr:hover td{
  background: #e9f1f9 !important;
}

.ant-table-tbody{
  overflow: auto;
}

.ant-picker-input>input::placeholder {
  color:rgb(125, 125, 125);
}

.ant-checkbox-wrapper{
  color: white;
}

.ant-table-pagination.ant-pagination{
  margin: 6px 0 0 0;
  font-size: smaller;
}

.spin-ele {
  min-height: 40vh;
  background-color: rgb(249, 251, 255);
  padding: 10vh;
  text-align: center;
  border-radius: 4px;
}

.spin-ele2 {
  background-color: rgb(249, 251, 255);
  text-align: right;
  padding-bottom: 2px;
}

.no-ele{
  min-height: 78vh;
  background-color: rgb(249, 251, 255);
}